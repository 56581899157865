<template>
  <div class="inner-container" :class="{ 'min-container': !getTabs }">
    <div class="page-header">
      <h1>{{ $t("DELEGATAIRE") }}</h1>
      <b-button
        size="sm"
        variant="success"
        class="button-succes-style ml-2 float-right mb-2"
        @click="handleStore"
        ><font-awesome-icon icon="plus-circle" /> {{ $t("NEW") }}</b-button
      >
    </div>

    <storeModalVue @updateList="setup(true)"></storeModalVue>

    <div class="content">
      <div class="content-header">
        <searchBar
          :placeholder="$t('DELEGATAIRE_TYPE_SEARCH')"
          :searchFunc="allDelegataire"
          @returnValue="updateSearch($event)"
        ></searchBar>
      </div>
      <p class="table-count">
        {{ gettotalDelegataireRows }} {{ $t("DELEGATAIRE") }}
      </p>
      <div class="table-rapport-style w-100">
        <table>
          <thead style="border-radius: 5px 0px 0px 0px">
            <tr>
              <th>
                {{ $t("NAME") }}
              </th>
              <th>{{ $t("SIREN") }}</th>
              <th class="action_head"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              role="row"
              class="ligneNormale"
              v-for="(data, i) in getAllDelegataire"
              :key="i"
              @click="handleUpdate(data)"
            >
              <td aria-colindex="1" role="cell">
                {{ data.nom }}
              </td>
              <td aria-colindex="2" role="cell">
                {{ data.siren }}
              </td>
              <td aria-colindex="3" role="cell" class="action_col">
                <b-button
                  class="button-succes-style"
                  size="sm"
                  variant="danger"
                  @click.prevent.stop="handleDelete(data)"
                >
                  <font-awesome-icon icon="trash" />
                </b-button>
                <b-button
                  class="button-succes-style"
                  size="sm"
                  variant="success"
                  @click.prevent.stop="handleUpdate(data)"
                >
                  <font-awesome-icon icon="pencil-alt" />
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <b-modal
        id="ModelConfigdelegataire"
        ref="ModelConfigdelegataire"
        hide-footer
      >
        <template #modal-header>
          <h5>{{ $t("EDIT") }}</h5>
          <b-button size="sm" @click="resetModal()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.028"
              height="17.028"
              viewBox="0 0 17.028 17.028"
            >
              <path
                id="Icon_material-close"
                data-name="Icon material-close"
                d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                transform="translate(-7.5 -7.5)"
                fill="#393939"
              />
            </svg>
          </b-button>
        </template>

        <form
          @submit.prevent="delegataireModelToconfig"
          v-if="delegataireToConfig"
        >
          <div class="center">
            <b-form-group :label="$t('NAME') + '*'">
              <b-form-input
                v-model="v$.delegataireToConfig.nom.$model"
                :state="validateState('nom')"
                oninput="this.value = this.value.toUpperCase()"
                aria-describedby="nom-feedback"
              ></b-form-input>
              <error-handle
                :list="v$.delegataireToConfig.nom.$errors"
                id="nom-feedback"
              ></error-handle>
              <div v-if="erreurlist.nom" class="error-message">
                <ul v-if="Array.isArray(erreurlist.nom)">
                  <span v-for="(e, index) in erreurlist.nom" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.nom }}</span>
              </div>
            </b-form-group>

            <b-form-group :label="$t('SIREN')">
              <b-form-input
                v-model="v$.delegataireToConfig.siren.$model"
                :placeholder="$t('SIREN') + ' ' + $t('DELEGATAIRE')"
                :state="validateState('siren')"
                oninput="this.value = this.value.toUpperCase()"
                aria-describedby="siren-feedback"
              ></b-form-input>
              <error-handle
                :list="v$.delegataireToConfig.siren.$errors"
                id="siren-feedback"
              ></error-handle>
              <div v-if="erreurlist.siren" class="error-message">
                <ul v-if="Array.isArray(erreurlist.siren)">
                  <span v-for="(e, index) in erreurlist.siren" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.siren }}</span>
              </div>
            </b-form-group>

            <b-form-group :label="$t('PARAGRAPH') + ' ' + $t('MONTANT_CEE')">
              <b-form-textarea
                v-model="v$.delegataireToConfig.paragraphCEE.$model"
                :state="validateState('paragraphCEE')"
                :placeholder="$t('PARAGRAPH') + ' ' + $t('MONTANT_CEE')"
                maxlength="1000"
                rows="3"
                required
              ></b-form-textarea>
              <error-handle
                :list="v$.delegataireToConfig.paragraphCEE.$errors"
                id="paragraphCEE-feedback"
              ></error-handle>
              <div v-if="erreurlist.paragraphCEE" class="error-message">
                <ul v-if="Array.isArray(erreurlist.paragraphCEE)">
                  <span
                    v-for="(e, index) in erreurlist.paragraphCEE"
                    :key="index"
                  >
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.paragraphCEE }}</span>
              </div>
            </b-form-group>

            <b-form-group :label="$t('PARAGRAPH') + ' ' + $t('MA_PRIME_RENOV')">
              <b-form-textarea
                v-model="v$.delegataireToConfig.paragraphMPR.$model"
                :state="validateState('paragraphMPR')"
                :placeholder="$t('PARAGRAPH') + ' ' + $t('MA_PRIME_RENOV')"
                maxlength="1000"
                rows="3"
                required
              ></b-form-textarea>
              <error-handle
                :list="v$.delegataireToConfig.paragraphMPR.$errors"
                id="paragraphMPR-feedback"
              ></error-handle>
              <div v-if="erreurlist.paragraphMPR" class="error-message">
                <ul v-if="Array.isArray(erreurlist.paragraphMPR)">
                  <span
                    v-for="(e, index) in erreurlist.paragraphMPR"
                    :key="index"
                  >
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.paragraphMPR }}</span>
              </div>
            </b-form-group>
            <b-form-group label="Placeholder disponible:">
              <ul>
                <li>
                  %DELEGATAIRE% => {{ $t("NAME") }} {{ $t("DELEGATAIRE") }}
                </li>
                <li>%SIREN% => {{ $t("SIREN") }} {{ $t("DELEGATAIRE") }}</li>
                <li>%MONTANT% => {{ $t("MONTANT") }}</li>
              </ul>
            </b-form-group>

            <b-form-group :label="$t('COMPANY_ID')" v-if="isSuperAdmin">
              <multiselect
                v-model="delegataireToConfig.entreprise"
                :searchable="true"
                :close-on-select="true"
                :multiple="false"
                :options="getAllentreprises"
                label="lib"
                track-by="id"
              >
                <template slot="noResult"> {{ $t("NO_DATA_FOUND") }} </template>
              </multiselect>
              <div v-if="erreurlist.entreprise_id" class="error-message">
                <ul v-if="Array.isArray(erreurlist.entreprise_id)">
                  <span
                    v-for="(e, index) in erreurlist.entreprise_id"
                    :key="index"
                  >
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.entreprise_id }}</span>
              </div>
              <div
                v-if="v$.delegataireToConfig.entreprise.$error"
                class="error-message"
              >
                {{ $t("REQUIRED_FIELD") }}
              </div>
            </b-form-group>
          </div>

          <div class="actionModel">
            <b-alert variant="warning" show v-if="errorU" class="messageError">
              {{ errorU }}
            </b-alert>
            <b-button variant="success" type="submit">
              <div class="block-spinner">
                {{ $t("EDIT") }}
                <div v-if="getDelegataireLoading" class="loading ml-2">
                  <div class="spinner-border" role="status"></div>
                </div>
              </div>
            </b-button>
          </div>
        </form>
      </b-modal>

      <deleteModalVue
        :loader="getDelegataireLoading"
        :elemToDelete="delegataireToDelete"
        :elemDelete="delete_Delegataires"
        @updateList="setup(false)"
      ></deleteModalVue>

      <b-pagination
        v-model="page"
        :total-rows="gettotalDelegataireRows"
        :per-page="perPage"
        aria-controls="my-table"
        pills
        last-number
        first-number
        align="center"
        size="sm"
        @change="pagination"
        class=""
      ></b-pagination>
    </div>

    <div class="text-center center-spin">
      <b-spinner
        v-if="getDelegataireLoading"
        variant="primary"
        label="Spinning"
        class="big-spin"
      ></b-spinner>
    </div>
    <CommentMarche to="/how-it-work/delegataire"></CommentMarche>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  maxLength,
  minLength,
  alphaNum,
} from "@vuelidate/validators";
import deleteModalVue from "../components/ui/deleteModal.vue";
import storeModalVue from "../components/Ajoutmodel/delegatairemodale.vue";
import searchBar from "../components/ui/searchBar.vue";
import errorHandle from "../components/ui/errorHandle.vue";
import CommentMarche from "../components/commentMarche.vue";

export default {
  components: {
    searchBar,
    deleteModalVue,
    storeModalVue,
    errorHandle,
    CommentMarche,
  },
  setup() {
    return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
  },
  data() {
    return {
      search: "",
      delegataireToConfig: null,
      delegataireToDelete: null,
      page: 1,
      perPage: 10,
      favoris: null,
      errorD: false,
      errorU: false,
      erreurlist: {
        nom: null,
        siren: null,
        paragraphCEE: null,
        paragraphMPR: null,
        entreprise_id: null,
      },
    };
  },
  validations() {
    return {
      delegataireToConfig: {
        nom: { required, maxLength: maxLength(100) },
        siren: { required, alphaNum, minLength: minLength(9) },
        paragraphCEE: { required, maxLength: maxLength(1000) },
        paragraphMPR: { required, maxLength: maxLength(1000) },
        entreprise: { required },
      },
    };
  },
  methods: {
    ...mapActions([
      "all_entreprises",
      "edit_Delegataires",
      "allDelegataire",
      "delete_Delegataires",
    ]),
    debounceInput: _.debounce(function (e) {
      this.page = 1;
      this.search = e.target.value;
      if (this.search === null || this.search === "") {
        this.setup();
      } else {
        this.searchDelegataire();
      }
    }, 1000),
    validateState(name) {
      const { $dirty, $error } = this.v$.delegataireToConfig[name];
      return $dirty ? !$error : null;
    },
    updateSearch(s) {
      this.page = 1;
      this.search = s;
    },

    selectentreprise(query) {
      if (query) {
        this.allDelegataire({
          page: this.page,
          per_page: this.perPage,
          entreprise_id: query.id,
        });

        this.currententreprise = query;
      } else {
        this.currententreprise = {
          lib: null,
        };
        this.allDelegataire({
          page: this.page,
          per_page: this.perPage,
        });
      }
      this.hideModal("entrepriseModal");
    },
    resetModal() {
      this.erreurlist = {
        nom: null,
        siren: null,
        paragraphCEE: null,
        paragraphMPR: null,
        entreprise_id: null,
      };
      this.errorU = null;
      this.hideModal("ModelConfigdelegataire");
    },
    async searchDelegataire() {
      await this.allDelegataire({
        search: this.search,
        page: this.page,
        per_page: this.perPage,
      });
    },
    hideModal(ref) {
      this.$refs[ref].hide();
    },
    handleUpdate(CAT) {
      this.delegataireToConfig = { ...CAT };
      this.$refs["ModelConfigdelegataire"].show();
    },
    handleDelete(CAT) {
      this.delegataireToDelete = { ...CAT };
      this.$root.$emit("bv::show::modal", "ModelDelete");
    },
    async delegataireModelToconfig() {
      const isFormCorrect = await this.v$.delegataireToConfig.$error;
      if (isFormCorrect) return;

      const data = {
        nom: this.delegataireToConfig.nom,
        siren: this.delegataireToConfig.siren,
        paragraphCEE: this.delegataireToConfig.paragraphCEE,
        paragraphMPR: this.delegataireToConfig.paragraphMPR,
        id: this.delegataireToConfig.id,
      };
      if (this.isSuperAdmin) {
        data.entreprise_id = this.delegataireToConfig.entreprise.id;
      }
      await this.edit_Delegataires(data)
        .then(() => {
          this.hideModal("ModelConfigdelegataire");
          this.errorU = null;
        })
        .catch((err) => {
          if (this.isObject(err)) {
            for (const [key, value] of Object.entries(err)) {
              if (!this.erreurlist[key]) {
                this.erreurlist[key] = value;
              }
            }
          } else {
            this.errorU = err;
          }
        });
    },
    isObject(obj) {
      return Object.prototype.toString.call(obj) === "[object Object]";
    },
    handleStore() {
      this.$root.$emit("bv::show::modal", "DelegataireModal");
    },

    pagination(paginate) {
      this.page = paginate;
      this.searchDelegataire();
    },
    setup(refresh = false) {
      if (refresh) this.page = 1;
      this.search = null;
      this.allDelegataire({ page: this.page, per_page: this.perPage });
      if (this.isSuperAdmin)
        this.all_entreprises({ page: this.page, per_page: 1000 });
    },
  },

  mounted() {
    this.setup(true);
  },

  computed: {
    ...mapGetters([
      "getAllentreprises",
      "getentrepriseLoading",
      "getAllDelegataire",
      "getDelegataireLoading",
      "gettotalDelegataireRows",
      "getTabs",
      "getOnlineUser",
    ]),
    isentrepriseAdmin() {
      return this.getOnlineUser.role == "user.entreprise-admin";
    },
    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
  },
};
</script>
<style lang="scss" scoped>
.actionModel {
  position: relative;
  padding: 52px 0 0;

  .messageError {
    position: absolute;
    top: 5px;
    bottom: 44px;
    color: #e4261b;
    margin: 0;
    font-size: 15px;
    background-color: #fff;
    border-color: #fff;
  }
}
</style>
